/* @jsx jsx */
import React, {useState, useEffect} from 'react';
import {jsx, css} from '@emotion/react'
import { useStaticQuery, graphql } from "gatsby";
import { mapEdgesToNodes } from "@utils/helpers";
import Video from "@components/molecules/video";
import {breakpoints, colors, transitions, unitConverter} from "@utils/base";
import Heading3 from "@components/atoms/headings/heading3/heading3";

const containerCSS = css`
  margin: ${unitConverter('80px 0 40px')};
`;

const randomVideosWrapperCSS = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-content: flex-start;
`;

const videoCSS = css`
  width: 100%;
  max-width: 25%;
  padding: ${unitConverter('10px')};
  transition: all ${transitions.fast} ease-in-out;
  margin-bottom: ${unitConverter('20px')};
  border: ${unitConverter('1px')} solid transparent;

  .main-image-wrapper {
    overflow: hidden;
    background-color: ${colors.background};
    border: ${unitConverter('1px')} solid ${colors.lightGray};
    .gatsby-image-wrapper {
      transition: all ${transitions.fast} ease-in-out;
    }
  }

  &:hover {
    background-color: ${colors.black};
    border: ${unitConverter('1px')} solid ${colors.lightGray};

    .main-image-wrapper {
      border: ${unitConverter('1px')} solid ${colors.lightGray};
      overflow: hidden;
    }

    &.even {
      .main-image-wrapper {
        .gatsby-image-wrapper {
          transform: scale(1.5) rotate(-2.5deg);
        }
      }
    }

    &.odd {
      .main-image-wrapper {
        .gatsby-image-wrapper {
          transform: scale(1.5) rotate(2.5deg);
        }
      }
    }
  }

  ${breakpoints.tabletLandscape} {
    max-width: 33%;
  }

  ${breakpoints.tabletPortrait} {
    max-width: 50%;
  }

  ${breakpoints.mobile} {
    max-width: 100%;
  }
`;

const headingCSS = css`
  font-size: ${unitConverter('32px')};
`;


/* Randomize array in-place using Durstenfeld shuffle algorithm */
const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const RandomVideos = ({currentVideoID, count = 4, title = "Some Random Videos", filter = args => args}) => {
  const data = useStaticQuery(query);
  const [videos] = useState(mapEdgesToNodes(data.videos))
  const [videosToShow, setVideosToShow] = useState([]);

  useEffect(() => {
    if (!videos || videos === 'undefined') return null
    let selectedVids = shuffle(videos).filter(video => video.ytVideoId !== currentVideoID)
    selectedVids = filter(selectedVids).slice(0, count)

    setVideosToShow(selectedVids)
  }, [videos]);

  return (
    <>
      {videosToShow.length > 0 && (
        <div css={containerCSS}>
          <Heading3 css={headingCSS}>{title}</Heading3>
          <div css={randomVideosWrapperCSS}>
            {videosToShow.map((video, index) => (
              <Video
                css={videoCSS}
                key={video.id}
                className={index % 2 === 0 ? 'odd' : 'even'}
                {...video}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
};

export default RandomVideos;

const query = graphql`
  query RandomVideosQuery {
    videos: allSanityVideo(sort: {order: DESC, fields: _updatedAt}) {
      edges {
        node {
          id
          _rawDescription(resolveReferences: {maxDepth: 10})
          ytVideoId
          pilot {
            _id
            handle
            social {
              facebook
              instagram
            }
            avatar {
              asset {
                _id
                fluid {
                  base64
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
          title
          slug {
            current
          }
          thumbnail {
            asset {
              _id
              fluid {
                base64
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  }
`;
