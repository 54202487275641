/* @jsx jsx */
import {graphql} from 'gatsby'
import GraphQLErrorList from '@components/graphql-error-list'
import Seo from '@components/seo'
import Layout from '@containers/layout'
import {css, jsx} from '@emotion/react'
import Heading1 from '@atoms/headings/heading1/heading1'
import Header from '@organisms/header'
import Paragraph from '@atoms/paragraph'
import ContentWrapper from '@molecules/contentWrapper'
import {unitConverter} from '@utils/base'
import RandomVideos from '@components/organisms/RandomVideos'

const get = require('lodash.get')

const NotFoundPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const {seo, global, header, footer} = site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const contentWrapperCss = css`
    text-align: center;

    h1  {
      margin-bottom: ${unitConverter('40px')};
      font-size: ${unitConverter('60px')};
    }

    p  {
      margin-bottom: ${unitConverter('80px')};
      font-size: ${unitConverter('20px')};
    }
  `

  return (
    <Layout footerSettings={footer} bgImage={get(global.backgroundImage, 'asset.fluid.src')}>
      <Seo title='404: Not found' />
      <Header title={seo.title} {...header} logoWidth={400} showSearch={false} />
      <ContentWrapper css={contentWrapperCss}>
        <Heading1>NOT FOUND</Heading1>
        <Paragraph>You just hit a page that doesn&#39;t exist... the sadness.</Paragraph>
      </ContentWrapper>
      <RandomVideos count={12} />
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      seo {
      title
      description
      keywords
      }
      header {
        tagline
        logo {
          asset {
            _id
            fixed {
              base64
              width
              height
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
        }
      }
      global {
        backgroundImage {
          asset {
            id
            _id
            fluid {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      footer {
        address
        tagline
        listOfLinks {
          __typename
          ... on SanityLink {
            linkText
            linkUrl
            _type
          }
        }
      }
    }
  }
`
